import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject, firstValueFrom, switchMap, catchError, of, tap, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {
  MembershipEnquiryDialogComponent
} from "../../shared/components/membership-enquiry-dialog/membership-enquiry-dialog.component";
import {
  FunctionRestrictedDialogComponent
} from "../../shared/components/function-restricted-dialog/function-restricted-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {UserInfo} from "../interfaces/user-crendential.interface";
import {Router} from "@angular/router";
import {UserService} from "./user.service";
import Bugsnag from "@bugsnag/js";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private httpWithoutInterceptor: HttpClient;

  constructor(private http: HttpClient,
              public dialog: MatDialog,
              private httpBackend: HttpBackend,
              private router: Router,
              private userService: UserService) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend)
  }

  private userPermsObs$ = new Subject();
  private userPerms: any = [];
  private roleVersion = {
    "role_id": "",
    "version": "",
    "account_type": ""
  };
  private user = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  };

  resetPermission() {
    this.user = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    };
    this.roleVersion = {
      "role_id": "",
      "version": "",
      "account_type": ""
    };
    this.userPerms = [];
    this.userPermsObs$.next(this.userPerms);
  }

  // Only For Resolver
  getUserPerm() {
    return this.userPermsObs$;
  }

  public get currentUserValue(): UserInfo {
    return JSON.parse(<string>localStorage.getItem('currentUser'))
  }

  async isRoleVersionChange() {
    let currentUser = this.currentUserValue;
    if (currentUser && currentUser.access) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.access}`
        })
      };
      // @ts-ignore
      const role_version = await firstValueFrom(this.httpWithoutInterceptor.get(`${environment.urls.organization.userRoleVersion}`, httpOptions).pipe(catchError((err: any) => {
        if (err.status == 401) {
          localStorage.removeItem('currentUser');
          this.logout();
          this.router.navigate(['/auth/login'])
        } else {
          throw(err);
        }
        return of({});
      })));
      // console.log("role_version", role_version);
      // @ts-ignore
      const role_id = role_version && role_version["role_id"] || "";
      // @ts-ignore
      const version = role_version && role_version["version"] || "";
      // @ts-ignore
      const accountType = role_version && role_version["account_type"] || "";
      if ((this.roleVersion["role_id"] != role_id) || (this.roleVersion["version"] != version || this.roleVersion["account_type"] != accountType)) {
        this.roleVersion["role_id"] = role_id;
        this.roleVersion["version"] = version;
        this.roleVersion["account_type"] = accountType;
        this.getUserPermFromServer();
        return true;
      } else {
        this.userPermsObs$.next(this.userPerms);
        return false;
      }
    } else {
      return false;
    }
  }

  getUserPermFromServer() {
    this.http.get(`${environment.urls.organization.permissionListUser}`).subscribe(re => {
      this.userPerms = re;
      this.userPermsObs$.next(this.userPerms);
    })
  }

  getUserPermFromServerObs(): Observable<any> {
    return this.http.get(`${environment.urls.organization.permissionListUser}`).pipe(
      tap(re => {
        this.userPerms = re;
        this.userPermsObs$.next(this.userPerms);
      }),
      catchError(err => {
        if (err.status == 401) {
          localStorage.removeItem('currentUser');
          this.logout();
          this.router.navigate(['/auth/login'])
          return throwError(err);
        } else {
          return throwError(err);
        }
      }))
  }

  hasPerm(perm_code: string) {
    if (this.isEmpty()) {
      Bugsnag.notify("Permission Array is Empty.");
      return false
    } else {
      return this.userPerms.indexOf(perm_code) >= 0;
    }
  }

  isAdmin() {
    return this.hasPermWithMembership("USER_EDIT_ORG")[0];
  }

  isPaid() {
    return !this.hasPerm("FREE_FRONT_LIST_PROJECT_FILTER");
  }

  isEmpty() {
    return this.userPerms.length == 0;
  }

  logout() {
    this.userPerms = [];
    this.roleVersion = {
      "role_id": "",
      "version": "",
      "account_type": ""
    };
    this.user = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    };
    this.userPermsObs$.next(this.userPerms);
  }

  hasPermWithMembership(perm_code: string): [boolean, string] {
    if (this.hasPerm("BASIC_" + perm_code)) {
      return [true, "basic"];
    } else if (this.hasPerm("FREE_" + perm_code)) {
      return [true, "free"];
    } else if (this.hasPerm(perm_code)) {
      return [true, 'pro']
    } else {
      return [false, "restricted"];
    }
  }

  getStaticPerms() {
    return this.userPerms;
  }

  updateUser(user: any) {
    this.user.email = user.email || '';
    this.user.firstName = user.firstName || '';
    this.user.lastName = user.lastName || '';
    this.user.phone = user.phone || '';
  }

  membershipRestrictPopUp(level = 'basic', title = 'Upgrade', info = '') {
    if (this.isAdmin() && level == "free") {
      this.dialog.open(MembershipEnquiryDialogComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '408px',
        data: {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          phone: this.user.phone,
          info: info,
        }
      })
    } else {
      this.dialog.open(FunctionRestrictedDialogComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '408px',
        data: {
          title: 'Upgrade',
          message: '',
          info: 'Please contact your admin to upgrade to professional account.',
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
        }
      })
    }
  }

  isPermHasBasic(perm: string) {
    const basic_map = this.getLicenseRequiredPerm()["basic_mapping"]
    // @ts-ignore
    return !!basic_map[perm];
  }

  isPermHasFree(perm: string) {
    const free_map = this.getLicenseRequiredPerm()["free_mapping"]
    // @ts-ignore
    return !!free_map[perm];
  }

  permMemberMap = {
    'QS_PROJECT': ['pro'],
    'QS_PROPERTY': ['pro'],
    'PROJECT_CREATE_PUBLISH_ORG': ['pro'],
    'FRONT_LIST_PROJECT_FILTER': ['pro', 'basic'],
    'PROJECT_COLLECT_SELF': ['pro'],
    'PROJECT_ARCHIVE_ORG': ['pro'],
    'LIST_PROJECT_ARCHIVED_ORG': ['pro'],
    'PROJECT_HUB_ACCESS': ['pro'],
    'PROJECT_REQUEST': ['pro'],
    'PROJECT_DESC_FEATURE_EDIT_ORG': ['pro'],
    'PROJECT_QS_TOP_SELF': ['pro'],
    'PROJECT_SETTING': ['pro'],
    'PROJECT_PRICE_LIST_EDIT_ORG': ['pro'],
    'PROJECT_PRICE_LIST_RECOMMEND_ORG': ['pro'],
    'FRONT_PROJECT_PRICE_LIST_FILTER': ['pro'],
    'PROJECT_TRAINING_CREATE_ORG': ['pro'],
    'PROJECT_TRAINING_EDIT_ORG': ['pro'],
    'PROJECT_TRAINING_DELETE_ORG': ['pro'],
    'LIST_PROJECT_TRAINING_ORG': ['pro'],
    'PROJECT_TRAINING_ACCESSIBILITY_ORG': ['pro'],
    'PROJECT_TRAINING_CREATE_PUB_ORG': ['pro'],
    'PROJECT_TRAINING_EDIT_PUB_ORG': ['pro'],
    'PROJECT_TRAINING_DELETE_PUB_ORG': ['pro'],
    'PROJECT_RESOURCE_UPLOAD_ORG': ['pro'],
    'PROJECT_RESOURCE_DELETE_ORG': ['pro'],
    'PROJECT_RESOURCE_DOWNLOAD_ORG': ['pro'],
    'PROJECT_RESOURCE_UPLOAD_PUB_ORG': ['pro'],
    'PROJECT_RESOURCE_DELETE_PUB_ORG': ['pro'],
    'PROJECT_SUB_AGENCY_MANAGEMENT': ['pro'],
    'PROJECT_SUB_AGENCY_INVITATION': ['pro'],
    'PROJECT_INVITATION_APPLICATION': ['pro', 'free'],
  }


  getPermRequireMember(perm: string): string[] {
    if (this.permMemberMap.hasOwnProperty(perm)) {
      // @ts-ignore
      return this.permMemberMap[perm];
    } else {
      return []
    }
  }

  getLicenseRequiredPerm() {
    return {
      "free_mapping": {
        'QS_PROJECT': 'FREE_QS_PROJECT',
        'QS_PROPERTY': 'FREE_QS_PROPERTY',
        'PROJECT_CREATE_PUBLISH_ORG': 'FREE_PROJECT_CREATE_PUBLISH_ORG',
        'FRONT_LIST_PROJECT_FILTER': 'FREE_FRONT_LIST_PROJECT_FILTER',
        'PROJECT_COLLECT_SELF': 'FREE_PROJECT_COLLECT_SELF',
        'PROJECT_ARCHIVE_ORG': 'FREE_PROJECT_ARCHIVE_ORG',
        'LIST_PROJECT_ARCHIVED_ORG': 'FREE_LIST_PROJECT_ARCHIVED_ORG',
        'PROJECT_HUB_ACCESS': 'FREE_PROJECT_HUB_ACCESS',
        'PROJECT_REQUEST': 'FREE_PROJECT_REQUEST',
        'PROJECT_DESC_FEATURE_EDIT_ORG': 'FREE_PROJECT_DESC_FEATURE_EDIT_ORG',
        'PROJECT_QS_TOP_SELF': 'FREE_PROJECT_QS_TOP_SELF',
        'PROJECT_SETTING': 'FREE_PROJECT_SETTING',
        'PROJECT_PRICE_LIST_EDIT_ORG': 'FREE_PROJECT_PRICE_LIST_EDIT_ORG',
        'PROJECT_PRICE_LIST_RECOMMEND_ORG': 'FREE_PROJECT_PRICE_LIST_RECOMMEND_ORG',
        'FRONT_PROJECT_PRICE_LIST_FILTER': 'FREE_FRONT_PROJECT_PRICE_LIST_FILTER',
        'PROJECT_TRAINING_CREATE_ORG': 'FREE_PROJECT_TRAINING_CREATE_ORG',
        'PROJECT_TRAINING_EDIT_ORG': 'FREE_PROJECT_TRAINING_EDIT_ORG',
        'PROJECT_TRAINING_DELETE_ORG': 'FREE_PROJECT_TRAINING_DELETE_ORG',
        'LIST_PROJECT_TRAINING_ORG': 'FREE_LIST_PROJECT_TRAINING_ORG',
        'PROJECT_TRAINING_ACCESSIBILITY_ORG': 'FREE_PROJECT_TRAINING_ACCESSIBILITY_ORG',
        'PROJECT_TRAINING_CREATE_PUB_ORG': 'FREE_PROJECT_TRAINING_CREATE_PUB_ORG',
        'PROJECT_TRAINING_EDIT_PUB_ORG': 'FREE_PROJECT_TRAINING_EDIT_PUB_ORG',
        'PROJECT_TRAINING_DELETE_PUB_ORG': 'FREE_PROJECT_TRAINING_DELETE_PUB_ORG',
        'PROJECT_RESOURCE_UPLOAD_ORG': 'FREE_PROJECT_RESOURCE_UPLOAD_ORG',
        'PROJECT_RESOURCE_DELETE_ORG': 'FREE_PROJECT_RESOURCE_DELETE_ORG',
        'PROJECT_RESOURCE_UPLOAD_PUB_ORG': 'FREE_PROJECT_RESOURCE_UPLOAD_PUB_ORG',
        'PROJECT_RESOURCE_DELETE_PUB_ORG': 'FREE_PROJECT_RESOURCE_DELETE_PUB_ORG',
        'PROJECT_SUB_AGENCY_MANAGEMENT': 'FREE_PROJECT_SUB_AGENCY_MANAGEMENT',
        'PROJECT_SUB_AGENCY_INVITATION': 'FREE_PROJECT_SUB_AGENCY_INVITATION',
      },
      "basic_mapping": {
        'QS_PROJECT': 'BASIC_QS_PROJECT',
        'QS_PROPERTY': 'BASIC_QS_PROPERTY',
        'PROJECT_CREATE_PUBLISH_ORG': 'BASIC_PROJECT_CREATE_PUBLISH_ORG',
        'PROJECT_COLLECT_SELF': 'BASIC_PROJECT_COLLECT_SELF',
        'PROJECT_ARCHIVE_ORG': 'BASIC_PROJECT_ARCHIVE_ORG',
        'LIST_PROJECT_ARCHIVED_ORG': 'BASIC_LIST_PROJECT_ARCHIVED_ORG',
        'PROJECT_HUB_ACCESS': 'BASIC_PROJECT_HUB_ACCESS',
        'PROJECT_REQUEST': 'BASIC_PROJECT_REQUEST',
        'PROJECT_DESC_FEATURE_EDIT_ORG': 'BASIC_PROJECT_DESC_FEATURE_EDIT_ORG',
        'PROJECT_QS_TOP_SELF': 'BASIC_PROJECT_QS_TOP_SELF',
        'PROJECT_SETTING': 'BASIC_PROJECT_SETTING',
        'PROJECT_PRICE_LIST_EDIT_ORG': 'BASIC_PROJECT_PRICE_LIST_EDIT_ORG',
        'PROJECT_PRICE_LIST_RECOMMEND_ORG': 'BASIC_PROJECT_PRICE_LIST_RECOMMEND_ORG',
        'FRONT_PROJECT_PRICE_LIST_FILTER': 'BASIC_FRONT_PROJECT_PRICE_LIST_FILTER',
        'PROJECT_TRAINING_CREATE_ORG': 'BASIC_PROJECT_TRAINING_CREATE_ORG',
        'PROJECT_TRAINING_EDIT_ORG': 'BASIC_PROJECT_TRAINING_EDIT_ORG',
        'PROJECT_TRAINING_DELETE_ORG': 'BASIC_PROJECT_TRAINING_DELETE_ORG',
        'LIST_PROJECT_TRAINING_ORG': 'BASIC_LIST_PROJECT_TRAINING_ORG',
        'PROJECT_TRAINING_ACCESSIBILITY_ORG': 'BASIC_PROJECT_TRAINING_ACCESSIBILITY_ORG',
        'PROJECT_TRAINING_CREATE_PUB_ORG': 'BASIC_PROJECT_TRAINING_CREATE_PUB_ORG',
        'PROJECT_TRAINING_EDIT_PUB_ORG': 'BASIC_PROJECT_TRAINING_EDIT_PUB_ORG',
        'PROJECT_TRAINING_DELETE_PUB_ORG': 'BASIC_PROJECT_TRAINING_DELETE_PUB_ORG',
        'PROJECT_RESOURCE_UPLOAD_ORG': 'BASIC_PROJECT_RESOURCE_UPLOAD_ORG',
        'PROJECT_RESOURCE_DELETE_ORG': 'BASIC_PROJECT_RESOURCE_DELETE_ORG',
        'PROJECT_RESOURCE_UPLOAD_PUB_ORG': 'BASIC_PROJECT_RESOURCE_UPLOAD_PUB_ORG',
        'PROJECT_RESOURCE_DELETE_PUB_ORG': 'BASIC_PROJECT_RESOURCE_DELETE_PUB_ORG',
        'PROJECT_SUB_AGENCY_MANAGEMENT': 'BASIC_PROJECT_SUB_AGENCY_MANAGEMENT',
        'PROJECT_SUB_AGENCY_INVITATION': 'BASIC_PROJECT_SUB_AGENCY_INVITATION',
        'PROJECT_INVITATION_APPLICATION': 'BASIC_PROJECT_INVITATION_APPLICATION',
      }
    }
  }


}
