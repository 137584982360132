import {Inject, Injectable} from '@angular/core';
import {WINDOW} from "../window";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  currentDomain: string = '';
  whiteLabelEnabled: boolean = true;
  currentLabelSetting: any = {
    companyInfo: {
      companyName: '21 Centry',
      companyLogo: 'https://assets.xomio.com/documents/501',
    },
    syncInfo: {
      syncLink: 'http://localhost:4200/auth/sync-link/32db3706-c861-40e5-9bb9-e064582fc3c8'
    },
    loginInfo: {
      email: 'realtycms_test_9@zhujia.com.au'
    },
    projectSelection: '14bc76b6-cc43-4998-a91d-3e1e8f2d0908',
    themeClass: 'century-21-theme',
    loginPageDisplay: {
      'background-container': {
        'background-image': 'url(https://assets.xomio.com/documents/408)',
      },
      'login-form': {
        'background': '#252526',
        'opacity': 0.9
      },
      'film': {
        'width': '100%',
        'height': '100%',
        'background-color': 'rgba(0,0,0,.03)',
        'position': 'absolute',
        'left': 0,
        'top': 0,
        'backdrop-filter': 'blur(10px)',
        'box-shadow': 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        '-webkit-backdrop-filter': 'blur(10px)',
        'background': '#252526',
        'opacity': 0.9
      },
      'apply-button': {
        'color': '#fff'
      },
      'footer': {
        'color': '#fff'
      }
    },
    mainLayoutDisplay: {
      'header-wrap': {
        'background-color': '#505050',
      },
      'header-icon-img': {
        'width': '150px',
        'height': '30px'
      },
      'header-icon-mobile-img': {
        'width': '120px',
        'height': '30px'
      },
      'nav-text': {
        color: '#fff'
      },
      'sync-button': {
        color: '#fff'
      }
    },
    filterDisplay: {
      'filter-container': {
        'background-color': '#dad4bf',
        'border-color': '#b2a071'
      }
    }
  };

  constructor(
    @Inject(WINDOW) private window: Window
  ) {
  }

  load(): void {
    this.currentDomain = this.window.location.hostname;
    this.whiteLabelEnabled = this.currentDomain in environment.whiteLabel.mapping;
    if (this.whiteLabelEnabled) {
      // @ts-ignore
      this.currentLabelSetting = environment.whiteLabel.mapping[this.currentDomain];
    }

  }
}
