import {Component} from '@angular/core';
import {OrganizationService} from "../../../core/services/organization.service";
import {ProjectService} from "../../../core/services/project.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {UserService} from "../../../core/services/user.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {GuestService} from "../../../core/services/guest.service";

@Component({
  selector: 'app-guest-link-layout',
  templateUrl: './guest-link-layout.component.html',
  styleUrls: ['./guest-link-layout.component.scss']
})
export class GuestLinkLayoutComponent {
  link: string | null = null;
  linkInfo: any;
}
