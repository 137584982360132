import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {EnsureImportedOnceModule} from "./ensure-imported-once.module";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {AuthTokenInterceptor} from "./interceptors/auth-token.interceptor";
import {AuthErrorInterceptor} from "./interceptors/auth-error.interceptor";
import {MockBackendInterceptor} from "./interceptors/mock-backend.interceptor";
import {interceptor_list} from "./interceptors";
import {resolver_list} from "./resolvers";
import {environment} from "../../environments/environment";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSelectModule,
  ],
  providers: [
    ...interceptor_list,
    ...resolver_list,
    ...environment.providers,
    DatePipe,
  ],
})
export class CoreModule extends EnsureImportedOnceModule {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
