import Bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular'
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {CoreModule} from "./core/core.module";
import {environment} from "../environments/environment";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {MatLegacySnackBarModule as MatSnackBarModule} from "@angular/material/legacy-snack-bar";
import {WhiteLabelService} from "./core/services/white-label.service";
import {WINDOW_PROVIDERS} from "./core/window";
import {DecimalPipe} from "@angular/common";

Bugsnag.start({apiKey: '8fe7d6d6e9902cb7631639f111e32c2a'})

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

let providers = [];

if (environment.production) {
  providers.push({provide: ErrorHandler, useFactory: errorHandlerFactory});
}

providers.push(WhiteLabelService,)
providers.push(DecimalPipe,)
providers.push({
  provide: APP_INITIALIZER,
  useFactory: (ds: WhiteLabelService) => () => {
    return ds.load()
  },
  deps: [WhiteLabelService],
  multi: true
})
providers.push(WINDOW_PROVIDERS)

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    MatTooltipModule,
    MatSnackBarModule,
    HttpClientXsrfModule.withOptions({cookieName: 'csrftoken', headerName: 'X-CSRFToken'}),
  ],
  providers: providers,
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
