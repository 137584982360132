import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MembershipEnquiryDialogComponent} from "../membership-enquiry-dialog/membership-enquiry-dialog.component";

@Component({
  selector: 'app-function-restricted-dialog',
  templateUrl: './function-restricted-dialog.component.html',
  styleUrls: ['./function-restricted-dialog.component.scss']
})
export class FunctionRestrictedDialogComponent implements OnInit {

  title: string = ''
  message: string = ''
  info: string = ''
  firstName: string = ''
  lastName: string = ''
  email: string = ''

  constructor(public dialogRef: MatDialogRef<FunctionRestrictedDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.info = this.data.info;
    this.firstName = this.data.firstName;
    this.lastName = this.data.lastName;
    this.email = this.data.email;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    // this.dialog.open(MembershipEnquiryDialogComponent, {
    //   data: {
    //     email: this.email,
    //     firstName: this.firstName,
    //     lastName: this.lastName
    //   }
    // })
    this.dialogRef.close({data: 'confirm'});
  }

}
