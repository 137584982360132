import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {catchError, Observable, of, map} from 'rxjs';
import {UserService} from "../services/user.service";
import {OrganizationService} from "../services/organization.service";

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.organizationService.getOrganizationDetail();
  }
}

@Injectable({
  providedIn: 'root'
})
export class MembershipResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.organizationService.getOrganizationMembershipInfo();
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationSubscriptionResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.organizationService.getOrganizationSubscriptionDetail().pipe(catchError(err => {
      if (err.status == 404) {
        return of(false);
      } else {
        throw err;
      }
    }));
  }
}


@Injectable({
  providedIn: 'root'
})
export class OrganizationRelationResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('relationID')) {
      this.router.navigateByUrl(`/manage/organizations`)
    }
    return this.organizationService.getOrganizationInfoByID(route.paramMap.get('relationID'));
  }
}

@Injectable({
  providedIn: 'root'
})
export class AustraliaAgencyResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.organizationService.checkAustraliaAgency().pipe(map(() => {
      return true
    }), catchError(err => {
      return of(false);
    }));
  }
}
