<div mat-dialog-title xmlns="http://www.w3.org/1999/html">
  <div class="">Upgrade to unlock advanced features</div>
  <div class="close-icon" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-dialog-content class="enquiry-form unit-filed-wrapper">
  <div class="upgrade-message">
    Wondering why an upgrade is beneficial? <br>
    With a paid subscription, you gain the ability to：
    <ul>
      <li>Create and upload your own projects</li>
      <li>Generate personalized marketing materials</li>
      <li>Utilize the Map function to visualize and explore projects by their geographical locations</li>
      <li>Expand network by inviting new channel companies</li>
      <li>Send direct in‐app messages and emails</li>
      <li>Add additional user accounts for your team</li>
    </ul>
  </div>


  <div class="button-container">
    <button mat-flat-button color="accent" (click)="upgrade()">
      Upgrade
    </button>
    <button mat-button color="error" (click)="closeDialog()">
      Cancel
    </button>
  </div>

</mat-dialog-content>
