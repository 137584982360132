import {
  ProjectBuildingResolver,
  ProjectFloorPlanDetailResolver,
  ProjectFloorPlanResolver,
  ProjectLevelDetailResolver, ProjectPriceIndexResolver,
  ProjectProfileDescriptionResolver,
  ProjectProfileResolver,
  ProjectsHubResolver,
  ProjectsResolver,
  ProjectTrainingCourseQAResolver,
  ProjectTrainingCourseResolver,
  ProjectTrainingResolver,
  ProjectUnitDetailResolver, ProjectUnitTagResolver
} from "./resolvers/projects.resolver";
import {UserResolver} from "./resolvers/user.resolver";
import {OrganizationResolver} from "./resolvers/organization.resolver";

export const resolver_list = [
  ProjectsResolver,
  ProjectsHubResolver,
  ProjectProfileResolver,
  ProjectProfileDescriptionResolver,
  ProjectTrainingResolver,
  ProjectTrainingCourseResolver,
  ProjectTrainingCourseQAResolver,
  ProjectBuildingResolver,
  ProjectFloorPlanResolver,
  ProjectLevelDetailResolver,
  ProjectFloorPlanDetailResolver,
  ProjectUnitDetailResolver,
  ProjectPriceIndexResolver,
  UserResolver,
  OrganizationResolver,
  ProjectUnitTagResolver,
]
