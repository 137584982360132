import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  private httpWithoutInterceptor: HttpClient;
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private currentVersion = ''

  public initVersionCheck(frequency = 1000 * 60 * 15) {
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }

  constructor(private http: HttpClient, private httpBackend: HttpBackend, private router: Router) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend)
  }

  getCurrentFrontendInfo() {
    return this.httpWithoutInterceptor.get(`${environment.urls.system.apiFrontendInfoURL}`)
  }

  private static hasHashChanged(currentHash: string, newHash: any) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }

  private checkVersion() {
    // timestamp these requests to invalidate caches
    this.getCurrentFrontendInfo().subscribe((response: any) => {
        const hash = response.angular_hash;
        const version = response.angular_version;
        const hashChanged = SystemService.hasHashChanged(this.currentHash, hash);

        // If new version, do something
        if (hashChanged) {
          // reload the page if hash changed
          location.reload()
        }
        // store the new hash so we wouldn't trigger versionChange again
        this.currentHash = hash;
        this.currentVersion = version
      },
      (err) => {
        console.error(err, 'Could not get version');
      })

  }
}
