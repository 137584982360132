<div mat-dialog-title>
  <div class="">RealtyCMS Help Center</div>
  <div class="close-icon" mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-dialog-content class="bulk-input-dialog-content-container">

  <div class="content-container">
    <div class="content-section">
      <div class="help-center-container">
        <div class="help-center-btn" (click)="openMenu()">
          <div class="btn-wrap">
            <div class="title body-1">I need help doing something (For All Users)</div>
            <div class="content body-2">
              View User Manual
            </div>
          </div>
        </div>
        <div class="help-center-btn disabled">
          <div class="btn-wrap">
            <div class="title body-1">Submit a system request (Coming Soon)</div>
            <div class="content body-2">
              Currently you can contact with your Account Manager directly during office hour.
            </div>
          </div>
        </div>
        <div class="help-center-btn disabled">
          <div class="btn-wrap">
            <div class="title body-1">Book a training session (Coming Soon)</div>
            <div class="content body-2">
              Currently you can contact with your Account Manager directly during office hour.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>
