import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpParams} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {environment} from "../../../environments/environment";
import {Subject, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  refreshUserInfo: Subject<any> = new Subject()
  cacheUserInfo: any = null;
  features: any[] = [];

  constructor(private http: HttpClient, private http_without_interceptor: HttpClient,
              handler: HttpBackend, private datePipe: DatePipe) {
    this.http_without_interceptor = new HttpClient(handler);
  }

  layoutUserRefresh() {
    return this.refreshUserInfo.asObservable();
  }

  getUserInfo() {
    return this.http.get(`${environment.urls.staff.staffInfo}`).pipe(
      tap((staffInfo: any) => {
        this.cacheUserInfo = staffInfo;
        this.features = staffInfo.organization_features;
      })
    );
  }

  getCacheUserInfo() {
    return this.cacheUserInfo;
  }

  getCacheUser(method: { (userInfo: any, isFromCache: any): void; (arg0: any, arg1: boolean): void; }) {
    if (this.cacheUserInfo) {
      return method(this.cacheUserInfo, true)
    } else {
      this.getUserInfo().subscribe(userInfo => {
        return method(userInfo, false)
      })
    }
  }

  delCacheUser() {
    this.cacheUserInfo = null;
    return null;
  }

  getUserInfoByID(staffID: string | null) {
    return this.http.get(`${environment.urls.staff.staff}${staffID}/`).pipe(
      tap((staffInfo: any) => {
        this.features = staffInfo.organization_features;
      })
    )
  }

  updateUser(staffID: string | null, data: any) {
    return this.http.patch(`${environment.urls.staff.staff}${staffID}/`, data)
  }

  uploadStaffFile(staffID: string, data: FormData) {
    return this.http.put(`${environment.urls.staff.staffFile}${staffID}/`, data)
  }

  getUsers(page: number = 1, pageSize: number = 10, filter: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filter) {
      if (filter.search) {
        params = params.append('search', `${filter.search}`)
      }
      if (filter.team) {
        params = params.append('team', `${filter.team}`)
      }
      if (filter.permission) {
        params = params.append('permission', `${filter.permission}`)
      }
      if(filter?.roles?.length > 0) {
        for(let r of filter.roles) {
          params = params.append('roles', `${r}`)
        }
      }
    }
    return this.http.get(`${environment.urls.staff.staff}`, {params: params})
  }

  deleteUser(id: string | null) {
    return this.http.delete(`${environment.urls.staff.staff}${id}/delete/`,)
  }

  requestPasswordReset(data: any) {
    return this.http_without_interceptor.post(`${environment.urls.staff.passwordReset}`, data)
  }

  confirmPasswordReset(data: any) {
    return this.http_without_interceptor.post(`${environment.urls.staff.passwordResetConfirm}`, data)
  }

  checkPasswordResetToken(data: any) {
    return this.http_without_interceptor.post(`${environment.urls.staff.passwordTokenCheck}`, data)
  }
}
