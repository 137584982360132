import {Injectable} from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemeServiceService {

  private _currentTheme = new Subject<string>();
  currentTheme = this._currentTheme.asObservable();
  private activeTheme: string = 'default-theme';

  constructor(private overlayContainer: OverlayContainer) {
  }

  setDarkTheme(theme: string): void {
    this._currentTheme.next(theme);
  }

  setTheme(theme: string): void {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    overlayContainerClasses.remove(this.activeTheme);
    overlayContainerClasses.add(theme);
    document.body.classList.remove(this.activeTheme);
    document.body.classList.add(theme);
    this.activeTheme = theme;
  }
}
