<div mat-dialog-title>
  <div class="">{{title}}</div>
  <div class="close-icon" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography">


  <div *ngIf="!htmlMessage" class="message">
    {{message}}
  </div>

  <div *ngIf="htmlMessage" [innerHTML]="message" class="message">
  </div>

  <div class="button-container">
    <button class="save-btn global-button" (click)="closeDialog()">
      Confirm
    </button>
  </div>

</mat-dialog-content>
