import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {

  states = [
    'NSW',
    'VIC',
    'QLD',
    'SA',
    'WA',
    'TAS',
    'NT',
    'ACT',
  ]

  constructor() {
  }

  hasCurrentState() {
    return !!localStorage.getItem('currentState')
  }

  getCurrentState() {
    return this.hasCurrentState() ? localStorage.getItem('currentState') : null
  }

  setCurrentState(state: string) {
    if (this.states.indexOf(state) >= 0) {
      localStorage.setItem('currentState', state);
    } else {
      localStorage.removeItem('currentState');
    }
  }
}
