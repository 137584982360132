<div class="auth-body">
  <div class="auth-body-content-wrap">
    <div class="authentication-card">
      <router-outlet></router-outlet>
    </div>
    <div class="bottom-line">
      <div class="title">
        <img class="logo" src="assets/icons/realtycms-logo-1024px.png">
        <div style="padding-top: 6px">RealtyCMS</div>
      </div>
      <span>Industry-wide Inventory Platform</span>
      <div class="img-icon">
        <img src="assets/images/android.jpg">
        <img src="assets/images/ios.jpg">
      </div>
    </div>
  </div>
</div>

