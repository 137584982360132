import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

interface NavObject {
  location: string;
  projectId: string | null;
  resourceId: string | null | undefined;
  resourceLabel: string | null | undefined;
  isCourseOwner?: boolean | null | undefined;
  agencyEdit?: string
}

interface FullNavObject {
  location: string;
  projectId: string | null;
  resourceId: string | null;
  edit: string;
  view: string;
}


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor() {
  }

  private isEditObs$ = new Subject();
  private navObs$ = new Subject();
  private projectIdObs$ = new Subject();

  getProjectTabArray(projectId: string | null) {
    if (projectId) {
      return [
        {
          label: "Profile",
          location: 'project-profile',
          edit: `/project/${projectId}/edit/profile`,
          view: `/project/${projectId}/profile`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        },
        {
          label: "Price List",
          location: 'project-price-list',
          edit: `/project/${projectId}/edit/price-list`,
          view: `/project/${projectId}/price-list`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        },
        {
          label: "Finance",
          location: 'project-finance',
          edit: `/project/${projectId}/profile`,
          view: `/project/${projectId}/finance`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        },
        // {
        //   label: "Share",
        //   location: 'project-share',
        //   edit: `/project/${projectId}/edit/share/project-articles`,
        //   view: `/project/${projectId}/share/project-articles`,
        //   projectId: projectId,
        // },
        {
          label: "Proposal",
          location: 'project-proposal',
          edit: `/project/${projectId}/edit/proposal`,
          view: `/project/${projectId}/proposal`,
          projectId: projectId,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          paidOnly: true,
        },
        {
          label: "Training",
          location: 'project-training',
          edit: `/project/${projectId}/edit/training`,
          view: `/project/${projectId}/training`,
          agencyEdit: `/project/${projectId}/edit/training`,
          projectId: projectId,
        },
        {
          label: "Files",
          location: 'project-resource',
          edit: `/project/${projectId}/edit/resource`,
          view: `/project/${projectId}/resource`,
          agencyEdit: `/project/${projectId}/edit/resource`,
          projectId: projectId,
        },
        {
          label: "Channels",
          location: 'project-agencies',
          edit: `/project/${projectId}/edit/agencies`,
          view: `/project/${projectId}/agencies`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        },
        // {
        //   label: "News & Promotion",
        //   location: 'project-news-promotion',
        //   edit: `/project/${projectId}/edit/news_and_promotion`,
        //   view: `/project/${projectId}/news_and_promotion`,
        //   projectId: projectId,
        // },
        {
          label: "Dataset",
          location: 'project-dataset',
          edit: `/project/${projectId}/profile`,
          view: `/project/${projectId}/dataset`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        }
      ]
    } else {
      return [{
        location: 'project-list',
        edit: `/project`,
        view: `/project`,
        projectId: null
      },]
    }
  }


  getFullNav(location: string, projectId: string | null) {
    if (!projectId) {
      return {
        location: 'project-list',
        edit: `/project`,
        view: `/project`,
        projectId: null
      }
    }
    switch (location) {
      case 'project-profile': {
        return {
          label: "Profile",
          location: 'project-profile',
          edit: `/project/${projectId}/edit/profile`,
          view: `/project/${projectId}/profile`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId
        }
      }
      case 'project-price-list': {
        return {
          label: "Price List",
          location: 'project-price-list',
          edit: `/project/${projectId}/edit/price-list`,
          view: `/project/${projectId}/price-list`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId
        }
      }
      case 'project-level-plan': {
        return {
          label: "Level",
          location: 'project-level-plan',
          edit: `/project/${projectId}/edit/levels`,
          view: `/project/${projectId}/levels`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        }
      }
      case 'project-floor-plan': {
        return {
          label: "Floor",
          location: 'project-floor-plan',
          edit: `/project/${projectId}/edit/floor-plans`,
          view: `/project/${projectId}/floor-plans`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        }
      }
      case 'project-share': {
        return {
          label: "Share",
          location: 'project-share',
          edit: `/project/${projectId}/edit/share/project-articles`,
          view: `/project/${projectId}/share/project-articles`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId
        }
      }
      case 'project-proposal': {
        return {
          label: "Proposal",
          location: 'project-proposal',
          edit: `/project/${projectId}/edit/proposal`,
          view: `/project/${projectId}/proposal`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
          paidOnly: true,
        }
      }
      case 'project-training': {
        return {
          label: "Training",
          location: 'project-training',
          edit: `/project/${projectId}/edit/training`,
          view: `/project/${projectId}/training`,
          agencyEdit: `/project/${projectId}/edit/training`,
          projectId: projectId
        }
      }
      case 'project-resource': {
        return {
          label: "Resource",
          location: 'project-resource',
          edit: `/project/${projectId}/edit/resource`,
          view: `/project/${projectId}/resource`,
          agencyEdit: `/project/${projectId}/edit/resource`,
          projectId: projectId
        }
      }
      case 'project-agencies': {
        return {
          label: "Sub Agencies",
          location: 'project-agencies',
          edit: `/project/${projectId}/edit/agencies`,
          view: `/project/${projectId}/agencies`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId
        }
      }
      case 'project-news-promotion': {
        return {
          label: "News & Promotion",
          location: 'project-news-promotion',
          edit: `/project/${projectId}/edit/news_and_promotion`,
          view: `/project/${projectId}/news_and_promotion`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId
        }
      }
      case 'project-dataset': {
        return {
          label: "Dataset",
          location: 'project-dataset',
          edit: `/project/${projectId}/edit/proposal`,
          view: `/project/${projectId}/dataset`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId,
        }
      }
      default: {
        return {
          label: "Profile",
          location: 'project-profile',
          edit: `/project/${projectId}/edit/profile`,
          view: `/project/${projectId}/profile`,
          agencyEdit: `/project/${projectId}/edit/proposal`,
          projectId: projectId
        }
      }
    }
  }

  getMode() {
    return this.isEditObs$;
  }

  updateMode(isEditMode: boolean) {
    this.isEditObs$.next(isEditMode);
  }

  getNav() {
    return this.navObs$;
  }

  updateNav(nav: NavObject) {
    let path = this.getFullNav(nav.location, nav.projectId);
    let fullNav = {
      label: path.label,
      location: nav.location,
      projectId: nav.projectId,
      resourceId: nav.resourceId,
      resourceLabel: nav.resourceLabel,
      edit: path.edit,
      view: path.view,
      agencyEdit: path.agencyEdit,
      isCourseOwner: nav.isCourseOwner
    }
    this.navObs$.next(fullNav);
  }

  getObs() {
    return this.navObs$;
  }

}
