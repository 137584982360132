import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http: HttpClient) {
  }

  getSuburb(filter: any = {}) {
    let params = new HttpParams();
    if (!!filter) {
      if (!!filter.search) {
        params = params.append('search', `${filter.search}`)
      }
      if (!!filter.uuids) {
        if (Array.isArray(filter.uuids)) {
          for (let uuid of filter.uuids) {
            params = params.append('uuid', `${uuid}`)
          }
        } else {
          params = params.append('uuid', `${filter.uuids}`)
        }
      }
    }
    return this.http.get(`${environment.urls.address.apiSuburbURL}`, {params: params})
  }
}
