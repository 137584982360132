import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language: string = 'en';

  constructor(private http: HttpClient) {
    if (environment.code !== 'local') {
      this.language = this.getLanguageFromUrl();
    }
  }

  get currentLanguage() {
    return this.language
  }

  getUserLanguage() {
    return navigator.language
  }

  getSupportLanguages() {
    return this.http.get(`${environment.urls.language.language}`)
  }

  getLanguageFromUrl() {
    return (window.location.pathname + window.location.search).split('/', 2)[1];
  }

  switchToLanguage(languageCode: string) {
    let url = (window.location.pathname + window.location.search).split('/');
    url.splice(1, 1, languageCode);
    // @ts-ignore
    window.location = window.location.origin + url.join('/')
  }
}
