import {ProjectsResolver} from "./resolvers/projects.resolver";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthTokenInterceptor} from "./interceptors/auth-token.interceptor";
import {AuthErrorInterceptor} from "./interceptors/auth-error.interceptor";
import {MockBackendInterceptor} from "./interceptors/mock-backend.interceptor";

export const interceptor_list = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthErrorInterceptor,
    multi: true
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: MockBackendInterceptor,
  //   multi: true
  // },
]
