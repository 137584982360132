import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-project-confirm-dialog',
  templateUrl: './project-confirm-dialog.component.html',
  styleUrls: ['./project-confirm-dialog.component.scss']
})
export class ProjectConfirmDialogComponent implements OnInit {

  title: string = ''
  message: string = ''
  htmlMessage: boolean = false;
  link: string = ''

  constructor(public dialogRef: MatDialogRef<ProjectConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.title = this.data.title;
    this.message = this.data.message;
    if(this.data.htmlMessage) {
      this.htmlMessage = true;
    }
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({data: 'confirm'});
  }

}
