import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import {LanguageService} from "../../../core/services/language.service";
import {UserService} from "../../../core/services/user.service";
import {StaffInfo} from "../../../core/interfaces/user.interface";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {catchError, of, Subscription} from "rxjs";
import {Location} from '@angular/common'
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {HelpCenterDialogComponent} from "../../components/help-center-dialog/help-center-dialog.component";
import {environment} from "../../../../environments/environment";
import {PermissionService} from "../../../core/services/permission.service";
import {AppService} from "../../../core/services/app.service";
import posthog from 'posthog-js'
import {SearchStateService} from "../../../core/services/search-state.service";
import {OrganizationService} from "../../../core/services/organization.service";
import {WhiteLabelService} from "../../../core/services/white-label.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  private history: string[] = []

  language: string = 'en'
  languages: any[] = []
  breadCrumb: boolean = true
  platformAdmin: string | null = null
  userInfo: StaffInfo = {
    uuid: '',
    firstName: '',
    lastName: '',
    avatar: '',
    email: '',
    phone: '',
    organizationName: '',
    organizationID: '',
    organizationRole: '',
    description: '',
    createdTime: '',
    lastLoginTime: '',
    status: '',
    qrCode: ''
  }
  onRefresh: Subscription | null = null;
  onSubRefresh: Subscription | null = null;
  permSubscription: Subscription | null = null;
  showRouterNavigation: boolean = false;
  sideNav: boolean = false;
  // @ts-ignore
  device: string = window["device"] || 'web';
  permsArray: string[] = [];
  permsDict = {
    "user_management": false,
    "channel_management": false,
    "portal_management": false,
    "crm": false,
    "article": false,
  }
  subStatus: 'active' | 'cancelled' | null = null;
  linkURL: string = '';
  features: any[] = [];
  whiteLabel: boolean = false;
  companyInfo: any;
  styles: any;
  syncLink: any;
  isAdmin: boolean = false;

  constructor(private _router: Router,
              private location: Location,
              private appService: AppService,
              public languageService: LanguageService,
              private route: ActivatedRoute,
              private userService: UserService,
              private authService: AuthenticationService,
              public dialog: MatDialog,
              private permService: PermissionService,
              private stateService: SearchStateService,
              private organizationService: OrganizationService,
              private whiteLabelService: WhiteLabelService,
              private _snackBar: MatSnackBar,
              private clipboard: Clipboard,
  ) {
    this.whiteLabel = this.whiteLabelService.whiteLabelEnabled;
    this.companyInfo = this.whiteLabelService.currentLabelSetting.companyInfo;
    this.styles = this.whiteLabelService.currentLabelSetting.mainLayoutDisplay;
    this.syncLink = this.whiteLabelService.currentLabelSetting.syncInfo.syncLink;
    this.platformAdmin = environment.platformAdmin;
    this.permsDict['crm'] = this.permService.hasPerm('LIST_CRM_LEAD_SELF')
    this.permsDict["user_management"] = this.permService.isAdmin();
    this.permsDict["channel_management"] = this.permService.hasPerm("LIST_PROJECT_CHANNEL_SELF") ||
      this.permService.hasPerm("PROJECT_SUB_AGENCY_MANAGEMENT")
    this.isAdmin = this.permService.isAdmin();
    this.permsDict["user_management"] = this.permService.isAdmin();
    this.permsDict["portal_management"] = this.permService.hasPerm("PROJECT_LABEL_PORTAL_MANAGEMENT")
    this.permsDict["article"] = this.permService.hasPerm("LIST_ARTICLE_ORG")
    this.route.data.subscribe((data: any) => {
      if (data.subscription) {
        this.subStatus = data.subscription.status
      }
      this.breadCrumb = data.noBreadcrumb;
      this.features = data.user.organization_features;
      this.userInfo = {
        uuid: data.user.uuid,
        firstName: data.user.first_name,
        lastName: data.user.last_name,
        email: data.user.email,
        phone: data.user.phone,
        avatar: data.user.avatar,
        organizationName: data.user.agency.name,
        organizationID: data.user.agency.uuid,
        organizationRole: data.user.roles.length > 0 ? data.user.roles[0].name : '',
        description: data.user.description,
        createdTime: data.user.created_at,
        lastLoginTime: data.user.last_login,
        status: data.user.status,
        qrCode: data.user.qr_code,
        is_paid: data.user.is_paid,
        pro: data.user.pro,
      }
    });
    this.onRefresh = this.userService.layoutUserRefresh().subscribe(() => {
      this.refreshUserInfo();
    });
    this.onSubRefresh = this.organizationService.layoutSubscriptionRefresh().subscribe(() => {
      this.refreshIsPaidInfo()
    })
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.showRouterNavigation = true;
      }
      if (e instanceof NavigationEnd) {
        this.history.push(e.urlAfterRedirects);
        this.showRouterNavigation = false;
        posthog.capture('$pageview')
      }
      if (e instanceof NavigationCancel) {
        this.showRouterNavigation = false;
      }
      if (e instanceof NavigationError) {
        this.showRouterNavigation = false;
      }
    });
    window.addEventListener("message",
      message => {
        if (message.type == "message") {
          try {
            const msg = JSON.parse(message.data);
            if (!!msg.action && msg.action === 'refreshUserInfo') {
              this.refreshUserInfo()
            }
          } catch (e) {
          }
        }
      });
    document.addEventListener("message", message => {
      // This is for android
      if (message.type == "message") {
        try {
          // @ts-ignore
          const msg = JSON.parse(message.data);
          if (!!msg.action && msg.action === 'refreshUserInfo') {
            this.refreshUserInfo()
          }
        } catch (e) {
        }
      }
    });
  }

  ngOnInit(): void {
    if (environment.posthog.enabled) {
      posthog.init('phc_XJRKyHN8JAnONgPj72CXDxU8GHdREwqKiZDLaIQxs6y', {api_host: 'https://app.posthog.com'});
      posthog.identify(
        this.userInfo.uuid, // Required. Replace 'distinct_id' with your user's unique identifier
        {email: this.userInfo.email, name: `${this.userInfo.firstName} ${this.userInfo.lastName}`},  // $set, optional
        {first_visited_url: this._router.url} // $set_once, optional
      );
    }
    this.languageService.getSupportLanguages().subscribe((x: any) => (this.languages = x.results));
  }

  ngOnDestroy() {
    if (!!this.onRefresh) {
      this.onRefresh.unsubscribe();
    }
    if (!!this.onSubRefresh) {
      this.onSubRefresh.unsubscribe();
    }
    if (!!this.permSubscription) {
      this.permSubscription?.unsubscribe()
    }
  }

  onChanges(event: any) {
    if (event === 'zh-hans' || event === 'en') {
      this.languageService.switchToLanguage(event);
    }

  }

  clickQuickSearch() {
    if (this.stateService.hasCurrentState()) {
      this._router.navigateByUrl(`/search/project-map?state=${this.stateService.getCurrentState()}`)
    } else {
      this._router.navigateByUrl('/search/project-map')
    }
  }

  clickMessage() {
    this._router.navigateByUrl('/notification/received')
  }

  goManageMyAccount() {
    if (this.device != 'web') {

      // @ts-ignore
      (window['ReactNativeWebView'] || window).postMessage(JSON.stringify({
        action: "UserManagement",
        data: {
          "token": this.authService.getAccessToken(),
          "user": this.userInfo,
        }
      }))
    } else {
      this._router.navigateByUrl(`/manage/my-account`)
    }

  }

  goArticle() {
    this._router.navigateByUrl(`/article/listing`)
  }

  goManageMyCompany() {
    this._router.navigateByUrl(`/manage/my-organization`)
  }

  goManageMyReservations() {
    this._router.navigateByUrl(`/manage/reservations`)
  }

  goToSubscription() {
    if (this.subStatus === 'cancelled') {
      this._router.navigateByUrl(`/manage/subscription/edit`);
    } else {
      this._router.navigateByUrl(`/manage/subscription`);
    }
  }

  goToUserManagement() {
    this._router.navigateByUrl(`/manage/users`)
  }

  goToWhiteLabel() {
    this._router.navigateByUrl(`/manage/white-label`)
  }

  goToProjectCopy() {
    this._router.navigateByUrl(`/manage/copy`)
  }

  goToProjectSync() {
    this._router.navigateByUrl(`/manage/sync`)
  }

  openPrivacy() {
    if (this.device != 'web') {
      this.appService.openLinkingView('https://realtycms.com.au/privacy-policy/')
    } else {
      window.open('https://realtycms.com.au/privacy-policy/', '_blank')?.focus();
    }

  }

  logout() {
    if (this.device != "web") {
      this.appService.deRegisterExpoTokenRequest();
    }
    this.authService.logout();
  }

  refreshUserInfo() {
    this.userService.getUserInfo().subscribe((x: any) => {
      this.features = x.organization_features;
      this.userInfo = {
        uuid: x.uuid,
        firstName: x.first_name,
        lastName: x.last_name,
        email: x.email,
        phone: x.phone,
        avatar: x.avatar,
        organizationName: x.agency.name,
        organizationID: x.agency.uuid,
        organizationRole: x.roles.length > 0 ? x.roles[0].name : '',
        description: x.description,
        createdTime: x.created_at,
        lastLoginTime: x.last_login,
        status: x.status,
        qrCode: x.qr_code,
      }
    })
  }

  refreshIsPaidInfo() {
    this.organizationService.getOrganizationSubscriptionDetail().pipe(catchError(err => {
      if (err.status == 404) {
        return of(false);
      } else {
        throw err;
      }
    })).subscribe((x: any) => {
      if (!x) {
        this.subStatus = null;
      } else {
        this.subStatus = x.status;
      }
    });
  }

  goBack() {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this._router.navigateByUrl('/')
    }
  }

  sideNavClick(status
                 :
                 boolean
  ) {
    this.sideNav = status
  }

  mobileNav(link
              :
              string
  ) {
    this._router.navigateByUrl(`${link}`);
    this.sideNavClick(false);
  }

  openHelpCenter() {
    const dialogRef = this.dialog.open(HelpCenterDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (!!result && !!result.data) {

      }
    });
  }

  copyLink() {
    this._snackBar.open(`Link copied.`, 'OK', {duration: 3000});
    this.clipboard.copy(this.syncLink);
    return this.syncLink;
  }

  goToSyncLink() {
    window.open(this.syncLink, "_blank");
  }

  getSyncButtonStyle() {
    return this.whiteLabel ? this.styles['sync-button'] : {};
  }

  getNavTextStyle() {
    return this.whiteLabel ? this.styles['nav-text'] : {};
  }

  getHeaderWrapStyle() {
    return this.whiteLabel ? this.styles['header-wrap'] : {};
  }

  getIconStyle() {
    return this.whiteLabel ? this.styles['header-icon-img'] : {};
  }

  getIconMobileStyle() {
    return this.whiteLabel ? this.styles['header-icon-mobile-img'] : {};
  }

}
