import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {OrganizationService} from "../../../core/services/organization.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-membership-enquiry-dialog',
  templateUrl: './membership-enquiry-dialog.component.html',
  styleUrls: ['./membership-enquiry-dialog.component.scss']
})
export class MembershipEnquiryDialogComponent implements OnInit {

  enquiryForm: FormGroup = new FormGroup({
    firstName: new FormControl('', []),
    lastName: new FormControl('', []),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required,]),
    message: new FormControl('', []),
  })
  title: string = 'Membership Enquiry'
  info: string = ''

  constructor(public dialogRef: MatDialogRef<MembershipEnquiryDialogComponent>,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private organizationService: OrganizationService,
  ) {
    if(!!data.title) {
      this.title = data.title;
    }
    if(!!data.info) {
      this.info = data.info;
    }
    this.enquiryForm.patchValue({
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      email: this.data.email,
      phone: this.data.phone,
    })
  }

  ngOnInit(): void {
  }

  get form(): { [key: string]: AbstractControl; } {
    return this.enquiryForm.controls;
  }

  upgrade() {
    this.router.navigateByUrl(`/manage/subscription`);
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
