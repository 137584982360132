import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {firstValueFrom, observable, Observable, of, take} from 'rxjs';
import {PermissionService} from "../services/permission.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionResolver implements Resolve<boolean> {
  constructor(private permService: PermissionService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.permService.isEmpty()) {
      return this.permService.getUserPermFromServerObs();
    }
    return of(this.permService.getStaticPerms())
  }
}
