import { Component } from '@angular/core';

@Component({
  selector: 'app-guest-login-layout',
  templateUrl: './guest-login-layout.component.html',
  styleUrls: ['./guest-login-layout.component.scss']
})
export class GuestLoginLayoutComponent {

}
