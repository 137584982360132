<div class="layout-container">
  <mat-progress-bar *ngIf="showRouterNavigation" class="nav-loading-progress-bar"
                    mode="indeterminate"></mat-progress-bar>
  <div class="header-section">
    <div class="header-wrap" [style]="getHeaderWrapStyle()">
      <div *ngIf="!whiteLabel" class="mobile-nav nav-bar-section-mobile">
        <div class="nav-bar">
          <button [ngStyle]="device !== 'web' ? {'display':'block'} : {}" mat-flat-button color="primary"
                  [routerLink]="'project/list'"
                  class="only-mobile mobile-menu">
            <div class="small-icon-button">
              <div class="small-icon-button-container">
                <div class="small-icon-button-icon-container">
                  <img class="small-icon-button-img" src="assets/icons/realtycms_logo.svg" alt="realty-icon">
                </div>
              </div>
              <div class="small-icon-text-container">
                <div class="small-icon-button-text">
                  Home
                </div>
              </div>
            </div>
          </button>
          <button [ngStyle]="device !== 'web' ? {'display':'block'} : {}" mat-flat-button color="primary"
                  class="only-mobile mobile-menu"
                  (click)="clickMessage()">
            <div class="small-icon-button">
              <div class="small-icon-button-container">
                <div class="small-icon-button-icon-container">
                  <mat-icon class="small-icon-button-icon">
                    mail
                  </mat-icon>
                </div>
              </div>
              <div class="small-icon-text-container">
                <div class="small-icon-button-text">
                  Message
                </div>
              </div>
            </div>
          </button>
          <button [ngStyle]="device !== 'web' ? {'display':'block'} : {}" mat-flat-button color="primary"
                  class="only-mobile mobile-menu"
                  (click)="goArticle()">
            <div class="small-icon-button">
              <div class="small-icon-button-container">
                <div class="small-icon-button-icon-container">
                  <mat-icon class="small-icon-button-icon">
                    collections_bookmark
                  </mat-icon>
                </div>
              </div>
              <div class="small-icon-text-container">
                <div class="small-icon-button-text">
                  Article
                </div>
              </div>
            </div>
          </button>
        </div>
        <div class="user-section only-mobile">
          <ng-container *ngIf="!whiteLabel">
            <button mat-button [matMenuTriggerFor]="avatarMenu">
              <img *ngIf="!!userInfo.avatar" class="user-avatar" [src]="userInfo.avatar" alt="user-avatar">
              <img *ngIf="!!!userInfo.avatar" class="user-avatar" src="assets/icons/default-avatar.svg"
                   alt="user-avatar">
            </button>
            <mat-menu #avatarMenu="matMenu" [class]="'user-menu'" xPosition="before">
              <div class="user-info">
                <div class="user-avatar">
                  <div class="avatar">
                    <img *ngIf="!!userInfo.avatar" [src]="userInfo.avatar" alt="realty-icon">
                    <img *ngIf="!!!userInfo.avatar" src="assets/icons/default-avatar.svg">
                  </div>

                </div>
                <div class="user-name body-1">{{ userInfo.firstName|titlecase }} {{ userInfo.lastName|titlecase }}</div>
                <div class="user-email body-2">{{ userInfo.email }}</div>
                <div class="user-manage-btn global-button" mat-button (click)="goManageMyAccount()">
                  Manage my account
                </div>
              </div>
              <div class="action-list">
                <button mat-menu-item (click)="goManageMyCompany()">My Company</button>
                <button mat-menu-item *ngIf="permsDict['user_management']" (click)="goToUserManagement()">User
                  Management
                </button>
                <button mat-menu-item *ngIf="permsDict['crm']" [routerLink]="['/crm/lead']">
                  RealtyCRM
                </button>
                <button mat-menu-item *ngIf="permsDict['user_management'] && features.indexOf('subscription') >= 0"
                        (click)="goToSubscription()">
                  <ng-container *ngIf="subStatus===null">Upgrade</ng-container>
                  <ng-container *ngIf="subStatus==='active'">Subscription</ng-container>
                  <ng-container *ngIf="subStatus==='cancelled'">Renew</ng-container>
                </button>
                <button mat-menu-item *ngIf="userInfo.email === platformAdmin" (click)="goToProjectCopy()">Copy
                  Projects
                </button>
                <button mat-menu-item *ngIf="userInfo.email === platformAdmin" (click)="goToProjectSync()">Sync
                  Projects
                </button>
                <!--            <button mat-menu-item (click)="openHelpCenter()">Help Center</button>-->
                <!--          <button mat-menu-item>Package Management</button>-->
                <!--          <button mat-menu-item>Help Center</button>-->
              </div>
              <div class="logout-section">
                <button mat-flat-button class="global-button logout-btn" (click)="logout()">Log Out</button>
              </div>
            </mat-menu>
          </ng-container>
        </div>
      </div>
      <div *ngIf="whiteLabel" class="mobile-nav white-label-nav">
        <a class="header-icon-mobile" mat-button [routerLink]="'project/list'">
          <img [style]="getIconMobileStyle()" [src]="companyInfo.companyHeadingLogo" alt="realty-icon">
        </a>
      </div>
      <div class="nav-bar-section">
        <div *ngIf="!whiteLabel" class="header-icon" [routerLink]="'project/list'">
          <img src="assets/icons/realtycms_logo.svg" alt="realty-icon">
        </div>
        <div class="nav-bar">
          <button mat-button [routerLink]="'/project/list'" i18n>Projects</button>
          <button mat-button *ngIf="permsDict['channel_management'] && !this.whiteLabel"
                  [routerLink]="'/manage/organizations'" i18n>
            Channel
          </button>
          <button *ngIf="!whiteLabel" mat-button [routerLink]="'/notification/received'" i18n>Messages</button>
          <div *ngIf="!whiteLabel && permsDict['portal_management']">
            <button mat-button [matMenuTriggerFor]="portalMenu" i18n>Portal</button>
            <mat-menu #portalMenu="matMenu" yPosition="below">
              <button mat-menu-item class="web-only" (click)="goToWhiteLabel()">Project Selection</button>
              <button mat-menu-item *ngIf="permsDict['user_management']" (click)="goManageMyReservations()">Reservation
              </button>
            </mat-menu>
          </div>
          <button mat-button *ngIf="permsDict['article'] && !this.whiteLabel"
                  [routerLink]="'/article/listing'" i18n>
            Article
          </button>
        </div>
        <div class="user-section">
          <ng-container *ngIf="!whiteLabel">
            <button mat-button [matMenuTriggerFor]="avatarMenu">
              <img *ngIf="!!userInfo.avatar" class="user-avatar" [src]="userInfo.avatar" alt="user-avatar">
              <img *ngIf="!!!userInfo.avatar" class="user-avatar" src="assets/icons/default-avatar.svg"
                   alt="user-avatar">
            </button>
            <mat-menu #avatarMenu="matMenu" [class]="'user-menu'" xPosition="before">
              <div class="user-info">
                <div class="user-avatar">
                  <div class="avatar">
                    <img *ngIf="!!userInfo.avatar" [src]="userInfo.avatar" alt="realty-icon">
                    <img *ngIf="!!!userInfo.avatar" src="assets/icons/default-avatar.svg">
                  </div>

                </div>
                <div class="user-name body-1">{{ userInfo.firstName|titlecase }} {{ userInfo.lastName|titlecase }}</div>
                <div class="user-email body-2">{{ userInfo.email }}</div>
                <div class="user-manage-btn global-button" mat-button (click)="goManageMyAccount()">
                  Manage my account
                </div>
              </div>
              <div class="action-list">
                <button mat-menu-item (click)="goManageMyCompany()">My Company</button>
                <button mat-menu-item *ngIf="permsDict['user_management']" (click)="goToUserManagement()">User
                  Management
                </button>
                <button mat-menu-item *ngIf="permsDict['crm']" [routerLink]="['/crm/lead']">
                  RealtyCRM
                </button>
                <button mat-menu-item *ngIf="permsDict['user_management'] && features.indexOf('subscription') >= 0"
                        (click)="goToSubscription()">
                  <ng-container *ngIf="subStatus===null">Upgrade</ng-container>
                  <ng-container *ngIf="subStatus==='active'">Subscription</ng-container>
                  <ng-container *ngIf="subStatus==='cancelled'">Renew</ng-container>
                </button>
                <button mat-menu-item *ngIf="userInfo.email === platformAdmin" (click)="goToProjectCopy()">Copy
                  Projects
                </button>
                <button mat-menu-item *ngIf="userInfo.email === platformAdmin" (click)="goToProjectSync()">Sync
                  Projects
                </button>
                <button mat-menu-item (click)="openPrivacy()">Privacy
                </button>
                <!--            <button mat-menu-item (click)="openHelpCenter()">Help Center</button>-->
                <!--          <button mat-menu-item>Package Management</button>-->
                <!--          <button mat-menu-item>Help Center</button>-->
              </div>
              <div class="logout-section">
                <button mat-flat-button class="global-button logout-btn" (click)="logout()">Log Out</button>
              </div>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="whiteLabel">
            <div class="sync-button" [style]="getSyncButtonStyle()" (click)="goToSyncLink()">
              <div class="button-content">Sync to my RealtyCMS</div>
              <div class="img-section"><img class="sync-img" src="assets/icons/realtycms_logo.svg"></div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="breadcrumb-section" *ngIf="breadCrumb">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="content-section">
    <router-outlet></router-outlet>
  </div>
</div>

